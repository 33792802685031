<template>
  <h2>
    <span>{{ subtitle }}</span>
  </h2>
</template>
<script>
export default {
  name: 'Subtitle',
  props: ['subtitle'],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>
<style scoped lang="scss">
@import 'Subtitle';
</style>
