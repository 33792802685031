<template>
  <section class="main-section bg-white has-no-scroll">
    <main-section-header title="도입설비 상세정보" :is-sticky="true" />
    <div class="container">
      <subtitle subtitle="설비 정보" />
      <template v-if="!isLoading">
        <ul class="detail-list">
          <detail-list-row
            v-for="(equipInfo, index) in equipmentInfo"
            :key="index"
            :info="equipInfo"
            :inputKey="equipInfo.key"
            :input-value="equipmentData[equipInfo.key]"
            :placeholder="equipInfo.placeholder"
            :dropdown-list="dropdownList[equipInfo.key]"
            @setSelectedIdList="onSetSelectedIdList"
            @saveInputValue="onSaveEditValue"
          >
            <span slot="label" class="label" :class="{ 'label-required': equipInfo.isRequired }">
              {{ equipInfo.label }}
            </span>
            <span slot="contents" class="contents" v-if="equipInfo.key === 'id'">
              {{ { mEqpId: equipmentData['mequipmentId'], eqpId: equipmentData['id'] } | formatEQPCode }}
            </span>
            <span slot="contents" class="contents" v-else-if="equipInfo.key === 'equipmentGroup'">
              <span class="dropdown-data" v-if="equipmentData[equipInfo.key]">
                {{ equipmentData[equipInfo.key].name }}
              </span>
            </span>
            <span slot="contents" class="contents" v-else-if="equipInfo.key === 'equipmentManagers'">
              <span
                class="dropdown-data"
                v-for="(equipmentManager, index) in equipmentData[equipInfo.key]"
                :key="index"
              >
                {{ equipmentManager.userName }}
              </span>
            </span>
            <span
              slot="contents"
              class="contents"
              v-else-if="dropdownList.hasOwnProperty(equipInfo.key)"
              :class="{ 'sensor-width': equipInfo.key === 'sensors' }"
            >
              <template v-if="equipInfo.key === 'mainManagerUser'">
                <span class="dropdown-data">{{ getManagerByPriority(1).userName || '-' }}</span>
              </template>
              <template v-else-if="equipInfo.key === 'subManagerUser'">
                <span class="dropdown-data" v-if="getManagerByPriority(2)">{{ getManagerByPriority(2).userName }}</span>
                <span v-else>-</span>
              </template>
              <template v-else>
                <template v-if="equipmentData[equipInfo.key].length">
                  <span v-for="(equipGroup, index) in equipmentData[equipInfo.key]" :key="index" class="dropdown-data">
                    {{ equipGroup.name || equipGroup.userName }}
                  </span>
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </template>
            </span>
            <span slot="contents" class="contents" v-else-if="equipInfo.key === 'qrCode'">
              <span class="qr-code">
                <span class="mr-10">
                  <a
                    :href="equipmentData.qrImage"
                    :download="`QRCode_${equipmentData['mequipmentId'] || equipmentData['id']}`"
                  >
                    다운로드
                  </a>
                </span>
                <span @click="onStartPrint">인쇄하기</span>
              </span>
            </span>
            <span slot="contents" class="contents" v-else-if="equipInfo.key === 'createDT'">
              {{ equipmentData[equipInfo.key] | localDateYYYYMMDD }}
            </span>
            <span slot="contents" class="contents" v-else>
              {{ equipmentData[equipInfo.key] || '-' }}
            </span>
          </detail-list-row>
        </ul>
      </template>
    </div>
    <qr-print
      v-if="equipmentData['qrCode']"
      qr-size="s"
      :qrCodeIdText="equipmentData['qrCode']['equipmentUuid']"
      :isShowQrCodeId="true"
      :isStartPrint="isStartPrint"
      :logoSvg="qrLogoSvg"
      :qrCodeId="JSON.stringify(equipmentData['qrCode'])"
      :title="{ mEqpId: equipmentData['mequipmentId'], eqpId: equipmentData['id'] } | formatEQPCode"
      :subtitle="qrSubtitle"
      @endPrint="onEndPrint"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import Subtitle from '@/component/subtitle/Subtitle';
import DetailListRow from '@/component/detailListRow/DetailListRow';
import FactoryEqpInfo from './FactoryEqpInfo.json';
import { QRLOGO } from '@/const/qrCodeSvg.js';
import getManagerName from '@/asset/js/getManagerName';

export default {
  name: 'FactoryEquipmentDetail',
  props: [],
  data() {
    return {
      equipmentInfo: '',
      equipmentId: null,
      equipmentData: {
        equipmentGroup: '',
        equipmentManagers: [],
        equipmentName: '',
        equipmentUuid: 0,
        factoryEquipmentManagers: [],
        id: 0,
        manufacturerCompanyName: '',
        modelName: '',
        sensors: [],
        serialNumber: null,
        updateDT: '',
        mainManagerUser: {},
        subManagerUser: {},
        mainManagerUserId: 0,
        subManagerUserId: 0,
      },
      edtData: {},
      dropdownList: {
        mainManagerUser: [],
        subManagerUser: [],
        sensors: [],
      },
      isStartPrint: false,
      qrLogoSvg: QRLOGO,
      qrTitle: '',
      qrSubtitle: '',
      isLoading: true,
      mainManagerUserList: [],
      subManagerUserList: [],
    };
  },
  computed: {},
  created() {
    this.initRenderData();
  },
  mounted() {},
  watch: {
    $route() {
      this.initRenderData();
    },
  },
  methods: {
    ...mapActions('factoryEquipments', ['GET_FACTORY_EQP', 'GET_FACTORY_EQP_QR', 'PUT_FACTORY_EQP']),
    ...mapActions('factorySensors', ['GET_FACTORY_SENSOR_MODEL']),
    ...mapActions('factoryUsers', ['GET_FACTORY_USER_NAME']),
    initRenderData() {
      this.equipmentId = this.$route.params.iqId;
      this.equipmentInfo = FactoryEqpInfo;
      this.getUserNameList();
      this.getSelfSensorNameList();
      this.getFactoryEqp(this.equipmentId);
    },
    initDataForEdit() {
      this.equipmentData.sensorIds = [];
      for (let i = 0; i < this.equipmentData.sensors.length; i++) {
        this.equipmentData.sensorIds.push(this.equipmentData.sensors[i].id);
      }
      this.equipmentData.mainManagerUser = this.getManagerByPriority(1);
      this.equipmentData.subManagerUser = this.getManagerByPriority(2);
      this.equipmentData.mainManagerUserId = this.getManagerByPriority(1).id;
      this.equipmentData.subManagerUserId = this.getManagerByPriority(2) && this.getManagerByPriority(2).id;
      this.qrSubtitle = this.equipmentData.modelName;
      this.qrTitle = this.equipmentData.id;
      this.dropdownList.mainManagerUser = this.filteredDropdownList(
        this.mainManagerUserList,
        (this.equipmentData.subManagerUser && this.equipmentData.subManagerUser.userName) || '',
      );
      this.dropdownList.subManagerUser = this.filteredDropdownList(
        this.subManagerUserList,
        (this.equipmentData.mainManagerUser && this.equipmentData.mainManagerUser.userName) || '',
      );
    },
    async getFactoryEqp(eqpId) {
      try {
        this.equipmentData = await this.GET_FACTORY_EQP(eqpId);
        this.initDataForEdit();
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getUserNameList() {
      const userNameList = await this.GET_FACTORY_USER_NAME();
      this.mainManagerUserList = userNameList;
      this.subManagerUserList = userNameList;
    },
    async getSelfSensorNameList() {
      try {
        const sensorNameList = await this.GET_FACTORY_SENSOR_MODEL();
        this.dropdownList.sensors = sensorNameList;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    onStartPrint() {
      this.isStartPrint = true;
    },
    onEndPrint() {
      this.isStartPrint = false;
    },
    async onSaveEditValue(inputKey, changedValue) {
      this.edtData[inputKey] = changedValue;
      await this.PUT_FACTORY_EQP([this.equipmentId, this.edtData]);
      await this.getFactoryEqp(this.equipmentId);
    },
    onSetSelectedIdList(contentIdList, selectedName, id) {
      switch (id) {
        case 'mainManagerUser':
          this.edtData.mainManagerUserId = contentIdList[0];
          if (selectedName) {
            this.dropdownList.subManagerUser = this.filteredDropdownList(this.subManagerUserList, selectedName);
          }
          break;
        case 'subManagerUser':
          this.edtData.subManagerUserId = contentIdList[0];
          if (selectedName) {
            this.dropdownList.mainManagerUser = this.filteredDropdownList(this.mainManagerUserList, selectedName);
          }
          break;
        case 'sensors':
          this.edtData.sensorIds = contentIdList;
          break;
      }
    },
    getManagerByPriority(priority) {
      return getManagerName(this.equipmentData.factoryEquipmentManagers, priority);
    },
    filteredDropdownList(list, selectedName) {
      return list.filter((managerData) => managerData.name !== selectedName);
    },
  },
  components: { Subtitle, DetailListRow, MainSectionHeader },
};
</script>

<style scoped lang="scss">
@import 'FactoryEquipmentDetail';
</style>
