const QRLOGO = `<svg width="37" height="43" viewBox="0 0 37 43" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 10.7227V19.9782L18.4765 9.21183V0L0 10.7227Z" fill="#0034AE" />
  <path d="M0 19.9781L18.4765 30.7008V21.4672L0 10.7227V19.9781Z" fill="#0081FF" />
  <path d="M18.4765 43.0007L0 32.1939V22.8574L18.4765 33.6705V43.0007Z" fill="#0034AE" />
  <path
    d="M37 10.7266L18.4766 21.468V30.7016L29.0163 24.496V27.6424L18.4766 33.6705V43.0007L37 32.2219V10.7266Z"
    fill="url(#paint0_linear)"
  />
  <path d="M13.0488 15.3683L18.4798 18.5022L23.8982 15.362L18.4767 12.2188L13.0488 15.3683Z" fill="#0034AE" />
  <path d="M18.4766 9.21183V0L34.4034 9.22429L26.4541 13.866L18.4766 9.21183Z" fill="#0081FF" />
  <defs>
  <linearGradient
    id="paint0_linear"
    x1="37.2072"
    y1="39.4356"
    x2="22.7929"
    y2="20.1352"
    gradientUnits="userSpaceOnUse"
  >
  <stop offset="0.3692" stop-color="#0034AE" />
  <stop offset="0.9072" stop-color="#0070F6" />
  </linearGradient>
  </defs>
  </svg>`;

export { QRLOGO };
